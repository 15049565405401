export const STRAPI_ENV = 'https://accenture.winvinayafoundation.org/cms/'
export const STRAPI_TOKEN =
  'af498c7624023bec2c5d4c5f7a129b9c73fb68b5b0af4426c35ff49126501b7bce9e5597d2e05aca1d274b969ffe813fa9af7e465d5a20a896c95f9b8e3f67e137fec3b42e9152b00179301f9631345fc2b60b1867536bae0ea68a38327933e198c21482573e83db8e078c23f4898b4a2ce27765c4917c47779612debbbd3e5f'
export const PERFORMANCE_REPORT_LINK =
  'https://app.powerbi.com/view?r=eyJrIjoiOWM5MmQ0YjYtNTk4OS00Nzg1LTlkMGItYzI1MjdkNzJmNTg5IiwidCI6ImNkMzY0NTJmLWFjMzQtNDRhNS1iZTMzLThiYWE4MDFhM2ZmZCJ9'
export const VOLUNTEER_SIGNUP =
  'https://docs.google.com/forms/d/e/1FAIpQLSfUNsHmzeDUfttKs8rzT4DGwpa1WJpAPEX0jZq8dZ8Dhlwb0Q/viewform'
export const NEW_REGISTRATION = 'https://docs.google.com/forms/d/e/1FAIpQLSf296ZTSdkxt3mHk3WFuoYRaXFYWMMflIoHIiWuiE_n6tKZaw/viewform'

export const WHATSAPP_NUMBER = '916360752091'

export const TRAINING_CARD_DATA = [
  {
    Header: {
      title: 'Software Development',
      subheader: 'Duration : 3 Months',
      src: '/home/training.svg',
      accessibility: { tabIndex: 0 }
    },
    Content: {
      text: [
        'Java',
        'MySQL',
        'HTML 5 and CSS3',
        'JavaScript',
        'ReactJS',
        'Professional English',
        'Soft Skills'
      ]
    }
  },
  {
    Header: {
      title: 'Banking and Financial Services',
      subheader: 'Duration : 3 Months',
      src: '/home/banking.svg',
      accessibility: { tabIndex: 0 }
    },
    Content: {
      text: [
        'Financial Accounting',
        'Core Banking',
        'Microsoft Office (Excel, Word, PowerPoint, Outlook)',
        'Tally / Zoho Books',
        'Microsoft Power BI (If needed)',
        'Professional English',
        'Soft Skills'
      ]
    }
  },
  {
    Header: {
      title: 'Software Testing',
      subheader: 'Duration : 3 Months',
      src: '/home/bug.svg',
      accessibility: { tabIndex: 0 }
    },
    Content: {
      text: [
        'Java (Basics)',
        'MySQL',
        'HTML5',
        'Manual Testing',
        'Test Automation (Selenium)',
        'Professional English',
        'Soft Skills'
      ]
    }
  },
  {
    Header: {
      title: 'Accessibility Testing',
      subheader: 'Duration : 3 Months',
      src: '/home/accessibility.svg',
      accessibility: { tabIndex: 0 }
    },
    Content: {
      text: [
        'Accessibility Testing',
        'Manual Testing',
        'HTML 5 and CSS3',
        'Professional English',
        'Soft Skills'
      ]
    }
  }
]

export const NEURODIVERSE_TRAINING_CARD_DATA = [
  {
    Header: {
      title: 'Basics',
      subheader: 'Duration : 3 Months',
      src: '/program/office.svg',
      accessibility: { tabIndex: 0 }
    },
    Content: {
      text: [
        'Basic of MS Office',
        'Document Remediation',
        'Zoho Books (basics)',
        'Soft Skills'
      ]
    }
  }
]

export const TESTIMONIALS_CARD_CONFIG = {
  variant: 'testimonial',
  grid: { spacing: 3 },
  item: { xs: 12, md: 4 }
}

export const TRAINING_CARD_CONFIG = {
  variant: 'training',
  grid: { spacing: 3 },
  item: { xs: 12, sm: 6, lg: 3 }
}

export const BLOGPOST_CARD_CONFIG = {
  variant: 'blog',
  grid: { columnSpacing: 3, rowSpacing: 3 },
  item: { xs: 12, md: 4 }
}
export const EVENTS_CARD_CONFIG = {
  variant: 'event',
  grid: { columnSpacing: { xs: 0, sm: 3 }, rowSpacing: { xs: 3 } },
  item: { xs: 12, md: 4 }
}

export const STORY_CARD_CONFIG = {
  variant: 'story',
  grid: { columnSpacing: { xs: 0, sm: 3 }, rowSpacing: { xs: 3 } },
  item: { xs: 12, lg: 6 }
}

export const SENSITIZATION_CARD_CONFIG = {
  variant: 'sensitization',
  grid: {
    columnSpacing: { xs: 0, sm: 3, md: 3 },
    rowSpacing: { xs: 3 }
  },
  item: { xs: 12, md: 6, lg: 4 }
}

export const SERVICES_CARD_CONFIG = {
  variant: 'service',
  grid: { rowSpacing: 3 },
  item: { xs: 12 }
}

export const SERVICES_CARD_DATA = [
  {
    Header: {
      title: 'Experiential Disability Awareness Orientation'
    },
    Media: {
      image: '/services/services.svg',
      alt: 'Experiential Disability Awareness Orientation'
    },
    Content: {
      text: [
        `The Experiential Disability Awareness Orientation by WinVinaya Foundation offers progressive companies a transformative insight into disability awareness. It equips 
            organizations to create inclusive environments and integrate Persons with Disabilities (PWD) into their workforce effectively. This orientation 
            provides insights into best practices for recruiting and training PWDs, including strategies for accessibility and accommodation. 
            One of the key components of this orientation is the lived experience sharing of PWD employees and candidates of WinVinaya. By providing a platform for PWDs to share their personal stories and challenges, companies gain direct perspectives on the 
            obstacles they face and the accommodations that can make a significant impact.`,
        `            In essence, WinVinaya Foundation's Experiential Disability Awareness Orientation is a crucial step towards fostering Diversity, Equity, and Inclusion (DEI), enabling organizations to create environments where every individual's potential is valued.`
      ]
    }
  },
  {
    Header: {
      title: 'Indian Sign Language Services'
    },
    Media: {
      image: '/services/services2.svg',
      alt: 'Indian Sign Language Services'
    },
    Content: {
      disableIcon: true,
      type: 'list',
      accessibility: { tabIndex: 0 },
      text: [
        `At WinVinaya Foundation, we recognize that effective communication is at the heart of inclusion. Our Indian Sign Language Services empower 
            companies to foster meaningful interactions, create a culture of understanding, and champion the principles of Diversity, Equity, and Inclusion (DEI).`,
        `We provide foundational course in both basics and domain-specific Indian Sign Language and equips employees with the tools to 
            engage in two-way communication with their deaf colleagues and can create a workplace where all voices are heard and valued.`,
        `
            Additionally, our Sign Language Interpretation Services play a 
            pivotal role in ensuring accessibility. We provide skilled interpreters for events, meetings, and training sessions, enabling seamless communication between deaf and hearing individuals.`
      ]
    }
  },
  {
    Header: {
      title: 'DEI Job Coaches for PWD Integration Services'
    },
    Media: {
      image: '/services/services3.svg',
      alt: 'DEI Job Coaches for PWD Integration Services'
    },
    Content: {
      text: `At WinVinaya Foundation, we recognize the significance of a supportive and inclusive transition. Our DEI Job Coaches for PWD Integration serve as crucial bridges, ensuring that individuals with disabilities are seamlessly integrated into the workforce. DEI Job Coach acts as a bridge between a person with disability and the inclusive company, in setting the expectations, mentoring both the parties, fine tuning ideas for a smoother integration. By addressing challenges proactively and fine-tuning approaches, DEI Job Coaches contribute to a smoother integration that benefits all stakeholders. They also foster an atmosphere where individuals with disabilities thrive, showcasing their potential and contributing meaningfully to the workforce.`
    }
  },
  {
    Header: {
      title: 'DE&I Advisory services'
    },
    Media: { image: '/services/services4.svg', alt: 'DE&I Advisory services' },
    Content: {
      text: `At WinVinaya Foundation, we believe that an effective DEI strategy is a cornerstone of a thriving and inclusive workplace. Through our DEI Advisory Services, we partner with companies to not only set a course for change but also to provide the tools and guidance necessary to realize their DEI vision. We provide comprehensive guidance to enable companies to create, enhance, and implement effective DEI strategies that drive meaningful change. Our services encompass a wide range of essential components, including the development of DEI policies, best practices, and metrics.`
    }
  },
  {
    Header: {
      title: 'Increase Supplier Diversity Quotient'
    },
    Media: {
      image: '/services/services5.svg',
      alt: 'Increase Supplier Diversity Quotient'
    },
    Content: {
      disableIcon: true,
      type: 'list',
      accessibility: { tabIndex: 0 },
      text: [
        `Make a transformative impact while receiving top-notch services by partnering with WinVinaya to increase your Supplier Diversity Quotient.`,
        `By choosing to collaborate with WinVinaya and its inclusive team, you play a role in creating opportunities for individuals with disabilities and promoting inclusivity within your business ecosystem. Our outsourcing services encompass a wide range of capabilities, including Accessibility Testing, Document Remediation, Accounting Automation, Manual Testing, Test Automation, and the creation of Interactive Power BI Dashboards. Our inclusive team brings a unique perspective and skill set to these projects, resulting in innovative solutions and meaningful contributions. Together, we can drive positive change and 
                champion a more diverse and inclusive marketplace.`
      ]
    }
  }
]

export const NEWSLETTER_CARD_CONFIG = {
  variant: 'newsletter',
  grid: { columnSpacing: 3, rowSpacing: 3 },
  item: { xs: 12, lg: 6 }
}

export const LEARN_CARD_CONFIG = {
  variant: 'learn',
  grid: { columnSpacing: 3, rowSpacing: 3 },
  item: { xs: 12, lg: 3 }
}

export const VOLUNTEER_CARD_DATA = [
  {
    Header: {
      title: 'Mentoring the Candidates',
      src: '/volunteer/mentor.svg'
    },
    Content: {
      text: 'Individual, Pair or Group mentoring with our candidates to help them on a holistic front – to get ready for the job and life.'
    }
  },
  {
    Header: {
      title: 'A Day in a Corporate - Workshops',
      src: '/volunteer/corporate.svg'
    },
    Content: {
      text: 'Conduct – ‘A Day in a Corporate’ workshops to strengthen the candidates’ understanding of the corporate sector.'
    }
  },
  {
    Header: {
      title: 'Roundtable Conferences',
      src: '/volunteer/conference.svg'
    },
    Content: {
      text: 'Jointly Organize or Participate in WinVinaya’s Inclusive Round Table Conferences.'
    }
  },
  {
    Header: {
      title: 'Co-Creating Training Materials',
      src: '/volunteer/trainer.svg'
    },
    Content: {
      text: 'Co-create training materials in skills where there is market demand along with WinVinaya.'
    }
  },

  {
    Header: {
      title: 'Provide Projects',
      src: '/volunteer/project.svg'
    },
    Content: {
      text: 'Provide us Projects that can be incorporated into the learning experience for candidates to enhance their competency for the job market.'
    }
  },
  {
    Header: {
      title: 'Inclusive Arts Events',
      src: '/volunteer/palette.svg'
    },
    Content: {
      text: 'Organize arts events like painting with people with Visual impairment,Dance with deaf, Pottery or clay modelling etc.'
    }
  },
  {
    Header: {
      title: 'Inclusive Sports Events',
      src: '/volunteer/sports.svg'
    },
    Content: {
      text: 'Conduct inclusive sports events like Blind cricket,Basket Ball with deaf, Chess with Visual Impaired candidates etc.'
    }
  },
  {
    Header: {
      title: 'Outdoor Activities',
      src: '/volunteer/outdoor.svg'
    },
    Content: {
      text: 'Volunteers can go for Trekking, visit historical places, Visit Organic Farms, Animal shelters etc. along with Persons with Disabilities.'
    }
  },
  {
    Header: {
      title: 'Role Plays',
      src: '/volunteer/theatre.svg'
    },
    Content: {
      text: 'Conduct Role plays to teach Banking concepts, Finance,Tax, Data analytical skills and more.'
    }
  },
  {
    Header: {
      title: 'Hackathons',
      src: '/volunteer/hackathons.svg'
    },
    Content: {
      text: 'Volunteers can conduct Hackathons for candidates along with WinVinaya Team to improve Design, Development, Team working skills.'
    }
  }
]

export const VOLUNTEER_CARD_CONFIG = {
  variant: 'volunteer',
  item: { xs: 12, md: 6, lg: 3 }
}

export const CORPENGAGEMENT_CARD_CONFIG = {
  variant: 'corp',
  grid: { columnSpacing: 3, rowSpacing: 3 },
  item: { xs: 12, lg: 6 }
}

export const CORPENGAGEMENT_CARD_DATA = [
  {
    Header: {
      title: 'Mock Interviews'
    },
    Content: {
      text: 'Volunteers can conduct mock interviews/external interviews which helps the candidates and students to shed their fear and anxiety in facing interviews. Providing authentic feedback on what the candidates did well and where they should improve aids in making them ready for their job interviews.'
    }
  },
  {
    Header: {
      title: 'Soft Skills Workshops'
    },
    Content: {
      text: 'Volunteers can conduct workshops and seminars on Confidence Building, Time Management, Crisis Management, Converting Challenges into Opportunities, Working with Dilemmas, Team Building, Conflict Resolution, Communication Skills etc.'
    }
  },
  {
    Header: {
      title: 'A Day in a Corporate-Workshops'
    },
    Content: {
      text: 'Volunteers can conduct ‘A Day in a Corporate’ workshops to explain how the corporate world is – by strengthening the candidates’ understanding of the corporate sector (Volunteers can explain each division or team over a period of many days).'
    }
  },
  {
    Header: {
      title: 'Corporate Expectations – Workshops'
    },
    Content: {
      text: 'Volunteers can conduct workshops of Corporate Expectations from both professional and personal point of view.'
    }
  },
  {
    Header: {
      title: 'Trends and Current Affairs – Sessions'
    },
    Content: {
      text: 'Volunteers can conduct virtual and offline sessions on Current Affairs, Current Market Trends, Employability Trends etc.'
    }
  },
  {
    Header: {
      title: 'Inclusive Events'
    },
    Content: {
      text: 'Volunteers can organize and be part of INCLUSIVE co-curricular events with our candidates, Parents, employees and the general public, on variety of themes.'
    }
  },
  {
    Header: {
      title: 'Mentoring'
    },
    Content: {
      text: 'Individual, pair or group of volunteers can mentor our candidates to help them on a holistic front – to get ready for the job and life.'
    }
  },
  {
    Header: {
      title: 'Quizzes and Tests'
    },
    Content: {
      text: 'Volunteers can help our candidates with online quizzes on technical knowledge, problem solving, critical thinking etc.'
    }
  },
  {
    Header: {
      title: 'Speeches and Seminars'
    },
    Content: {
      text: 'Volunteers can organize speeches and short-term workshops on various topics of interest in 21st century world and many more.'
    }
  },
  {
    Header: {
      title: 'Provide Projects'
    },
    Content: {
      text: 'Volunteers can provide us projects that can be incorporated into the learning experience for candidates to enhance their competency for the job market.'
    }
  }
]

export const TEAM_CARD_CONFIG = {
  variant: 'team',
  grid: { columnSpacing: { md: 3 }, rowSpacing: 3 },
  item: { xs: 12, md: 6, lg: 4 }
}

export const FOUNDERS_CARD_DATA = [
  {
    Header: {
      title: 'Akila',
      subheader:
        'A Good Friend, Leader, Co-worker, Mom & Learner who is full of tremendous positive energy and capacity to attract the same energy',
      accessibility: { tabIndex: 0 }
    },
    Media: { image: '/cofounder.svg' }
  },
  {
    Header: {
      title: 'Shiva',
      subheader:
        'Our Honest, Funny, Helpful Leader who believes in dispelling darkness of ignorance like the Sun, protect and nurture people around like the Bodhi tree',
      accessibility: { tabIndex: 0 }
    },
    Media: { image: '/founder.svg' }
  }
]

export const COURSE_CARD_CONFIG = {
  variant: 'course',
  grid: { columnSpacing: { md: 3 }, rowSpacing: { xs: 3, md: 0 } },
  item: { xs: 12, md: 4 }
}

export const COURSE_DATA = [
  {
    Header: {
      title: 'iLearn',
      subheader: 'With Micro Learning Videos'
    },
    Media: { src: '/currentwork/learn.svg' },
    accessibility: {
      ariaLabel: 'Module 1 iLearn with Micro Learning Videos'
    }
  },
  {
    Header: {
      title: 'iPractice',
      subheader:
        'Hundreds of exercises with a combination of auto evaluation and expert evaluation'
    },
    Media: { src: '/currentwork/practice.svg' },
    accessibility: {
      ariaLabel:
        'Module 2 iPractice Hundreds of exercises with a combination of auto evaluation and expert evaluation'
    }
  },
  {
    Header: {
      title: 'iAssess',
      subheader:
        'Exercises to be solved within a time duration like exams/ interview'
    },
    Media: { src: '/currentwork/assess.svg' },
    accessibility: {
      ariaLabel:
        'Module 3 iAssess Exercises to be solved within a time duration like exams/ interview'
    }
  }
]

export const PAYMENT_CARD_CONFIG = {
  variant: 'corp',
  grid: { columnSpacing: 3, rowSpacing: 3 },
  item: { xs: 12, lg: 6 }
}

export const PAYMENT_CARD_DATA = [
  {
    Header: {
      title: 'Support A Student - Training on English Rs. 5,000/-'
    },
    Content: {
      text: 'You can sponsor the training of one PWD (Person with Disability) candidate in functional English skills. As you know, 70% of the PWD students are from rural areas. These students struggle a lot to get an appropriate job as the main impediment is their English skills. So, training them on English is an important first step to ensure their job-readiness. The cost of this training is Rs. 5,000/- per candidate.'
    },
    Amount: 5000
  },
  {
    Header: {
      title: 'Support A Student - Training on English and Soft Skills Rs. 10,000/-'
    },
    Content: {
      text: 'You can sponsor the training of one PWD (Person with Disability) candidate in training on Employability Skills (English and Soft skills). As you know, 70% of the PWD students are from rural areas. These students struggle a lot to get an appropriate job due to lack of Employability Skills which includes English skills, Improving Active Listening, Communicating with Confidence, Conducting Mock Interviews, Simulating Role Plays and more. The cost of this training is Rs. 10,000/- per candidate.'
    },
    Amount: 10000
  },
  {
    Header: {
      title: 'Support A Student - Training on English, Soft Skills and IT or BFSI Rs. 25,000/-'
    },
    Content: {
      text: 'You can sponsor the training of one PWD (Person with Disability) candidate on English, Soft skills and IT or BFSI based on the degree completed by the student. The corporates expecting the candidates to possess the skills and knowledge as per the latest industry trends. So, apart from training them on English, Aptitude and soft skills, we train them on latest SW technologies and also on BFSI sector. This ensures their job-readiness to a great extent. The cost of this training is Rs. 25,000/- per candidate.'
    },
    Amount: 25000
  },
  {
    Header: {
      title: 'Event O Impact Rs. 15,000/-'
    },
    Content: {
      text: 'You can help us organizing events, bankroll for guest lectures and help these candidates get good quality exposure to corporate trends and life skills. The sponsorship for organizing these high impact events is Rs. 15,000/- per event.'
    },
    Amount: 15000
  },
  {
    Header: {
      title: 'Helping Hands - General Donation (Any amount)'
    },
    Content: {
      text: 'This could help us training the PWD candidates, creating digital learning materials for the HSI (Hearing and Speech Impaired) candidates in Indian Sign Language, conducting employability events, and more.'
    },
    Amount: 0
  },
]

export const JOB_CARD_CONFIG = {
  variant: 'job',
  grid: { columnSpacing: 3, rowSpacing: 3 },
  item: { xs: 12, lg: 4 }
}

export const DISABILITY_CATEGORIES = [
  'Autism Spectrum Disorder',
  'Leprosy Cured',
  'Intellectual Disability',
  'Multiple Disabilities',
  'Acid Attack Survivors',
  'Locomotor Disability',
  'Muscular Dystrophy',
  'Hearing Impairment',
  'Cerebral Palsy',
  'Hemophilia',
  'Thalassemia',
  'Mental Illness',
  'Low Vision',
  'Blindness',
  'Dwarfism'
]

export const ACHIEVEMENT_SCORES = [
  { value: '873', unit: 'Candidates Completed Training' },
  { value: 386, unit: 'Candidates Placed' },
  { value: 168, unit: 'Candidates Available for Recruitment' },
  { value: '₹32,000', unit: 'Average Salary' }
]

export const RECORD_LIMIT = 6

export const NAVLINKS = [
  {
    label: 'Home',
    href: '/'
  },
  {
    label: 'About Us',
    href: '/about-us'
  },
  {
    label: 'WinVinaya Academy',
    submenu: {
      id: 'winvinaya-academy-menu',
      links: [
        {
          label: 'What We Do',
          href: '/currentwork'
        },
        {
          label: 'Our Programs',
          href: '/program'
        },
        {
          label: 'Blog',
          href: '/blog'
        },
        {
          label: 'Newsletters',
          href: '/newsletter'
        }
      ]
    }
  },
  {
    label: 'Achievements',
    submenu: {
      id: 'achievements-menu',
      links: [
        {
          label: 'Testimonials',
          href: '/testimonials'
        },
        {
          label: 'Success Stories',
          href: '/stories'
        },
        {
          label: 'Performance Report',
          href: '/performance'
        }
      ]
    }
  },
  {
    label: 'Engage with Us',
    submenu: {
      id: 'engage-with-us-menu',
      links: [
        {
          label: 'Get Sensitized',
          href: '/sensitization'
        },
        {
          label: 'Learn Sign Language',
          href: '/learn'
        },
        {
          label: 'Volunteer',
          href: '/volunteer'
        },
        {
          label: 'Internship',
          href: '/internship'
        },
        {
          label: 'DE&I Services',
          href: '/services'
        },
        {
          label: 'Corporate Engagement',
          href: '/corpEngagement'
        },
        {
          label: 'Careers',
          href: '/careers'
        }
      ]
    }
  },
  {
    label: 'Events',
    href: '/events'
  },
  {
    label: 'Donate',
    href: '/donate'
  },
  {
    label: 'Contact Us',
    href: '/contact'
  }
]

export const DRAWERLINKS = [
  ...NAVLINKS.slice(0, NAVLINKS.length - 1),
  {
    label: 'Register',
    href: NEW_REGISTRATION
  },
  ...NAVLINKS.slice(NAVLINKS.length - 1)
]

export const FOOTER_LINKS = {
  'WinVinaya Academy': [
    { linkText: 'What We Do', linkTo: '/currentwork' },
    { linkText: 'Our Programs', linkTo: '/program' },
    { linkText: 'Blog', linkTo: '/blog' },
    { linkText: 'Newsletters', linkTo: '/newsletter' },
  ],
  Achievements: [
    { linkText: 'Testimonial', linkTo: '/testimonials' },
    { linkText: 'Success Stories', linkTo: '/stories' },
    { linkText: 'Performance report', linkTo: '/performance' }
  ],
  'Engage with Us': [
    { linkText: 'Get Sensitized', linkTo: '/sensitization' },
    { linkText: 'Learn Sign Language', linkTo: '/learn' },
    { linkText: 'Volunteer', linkTo: '/volunteer' },
    { linkText: 'Internship', linkTo: '/internship' },
    { linkText: 'DE&I Services', linkTo: '/services' },
    { linkText: 'Corporate Engagement', linkTo: '/corpEngagement' },
    { linkText: 'Careers', linkTo: '/careers' }
  ]
}

export const SOCIALMEDIA_ICON_PROPS = {
  sx: { width: 24, height: 24, marginRight: 1, color: 'white' }
}

export const INTERNSHIP_BENEFITS = [
  'Get hands-on experience working with Founders, Leaders and Trainers having great expertise in IT and NGO Sectors (Truthfully, this is a very unique and rare combination)',
  'Get exposure in various areas of NGO Management, Projects and opportunity to Interact with the beneficiaries (well, that is the advantage of working in a Growing and mid-sized NGO)',
  'Work with WinVinaya Academy, India’s First Digital Learning Academy for Persons with Disabilities',
  'Learn to work with an Inclusive Team and working with Persons with Disabilities to build up your personality and develop your skills at both a personal and a professional level',
  'Build your network with inspiring and motivating group of people'
]
export const INTERNSHIP_SIGNUP = 'https://docs.google.com/forms/d/e/1FAIpQLSf9ABZEdHgTX8daJebbkoR1KZ5FLx0v4d2drrpE7FO4OIfE9w/viewform?pli=1'
export const INTERNSHIP_NOTES = [
  'The minimum duration of internship with us is one month. We provide both Online and Offline internship opportunities.',
  'Applying from abroad? Make sure all necessary documentation, approvals and visa formalities are in place before applying. You will need to share all your documents and permissions for us to process your application for internship with WVF.',
  'All internships at WinVinaya are unpaid.',
  'Unless you have chosen the Virtual Internship option, we do not encourage Work-From-Home for interns.',
  'All interns are eligible for all the holidays of the organization as applicable to an employee during the duration of the internship. Interns must give a prior notice of two days before applying for a leave. Unless it is a medical emergency, we discourage interns to apply for leave during the internship period.',
  'Detailed orientation about the duties will be given to the interns after they are onboarded.'
]
export const SOCIALMEDIA_LINKS = {
  LinkedIn: {
    icon: { width: 24, height: 24 },
    linkTo: 'https://www.linkedin.com/company/winvinaya-foundation/'
  },
  Facebook: {
    icon: {
      width: 26,
      height: 26
    },
    linkTo: 'https://www.facebook.com/WinVinayaFoundation/'
  },
  Instagram: {
    icon: { width: 24, height: 24 },
    linkTo: 'https://www.instagram.com/winvinayafoundation/'
  },

  Youtube: {
    icon: { width: 24, height: 24 },
    linkTo: 'https://www.youtube.com/channel/UCNp26WU8vcLJ0vPkPpk1T8A'
  },
  Twitter: {
    icon: { width: 24, height: 24 },
    linkTo: 'https://twitter.com/WinVinayaF'
  }
}

export const SHARE_URL = 'https://winvinayafoundation.azurewebsites.net'
export const EMAIL_SUBJECT = 'WinVinaya Foundation - Visit us here!!'

export const EMAIL_DESC = `WinVinaya Foundation- Enabling an inclusive society .
We strongly believe in the power of collective efforts and always on a lookout for people who have the enthusiasm , courage and dedication to make a difference.
If you are someone who prefers direct communication , feel free to visit us at:`

export const LOCATION_DETAILS = [
  {
    displayName: 'Bengaluru',
    name: 'Bengaluru',
    address: [
      {
        type: 'Registered Address',
        disableDirectionsShare: true,
        details: ['25/3 Brindavan , 3rd Cross ,\n Saraswathi Puram , IIM Post ,\n Bengaluru , Karnataka – 560 076',
          '25/3 Brindavan , 3rd Cross ,\n Saraswathi Puram , IIM Post ,\n Bengaluru , Karnataka – 560 076']
      },
      {
        type: 'Operational Address',
        details: [`WinVinaya Foundation , \n Royal Residency , 55 , 4th Cross Road , \n BDA Layout , BTM 4th Stage , \n Bengaluru ,  Karnataka – 560076`,
          `WinVinaya Foundation , Royal Residency , \n 55, 4th Cross Road , BDA Layout , BTM 4th Stage, \n Bengaluru ,  Karnataka – 560076`]
      }
    ]
  },
  {
    displayName: 'Tirupur ( Jointly run with The Manoharan Charitable Trust )',
    name: 'Tirupur',
    address: [
      {
        details: [`SF No. 34 , Sri Venkateswara Nagar, \n 1st Street , 
Near Velan Hotel ,\n Kangayem Main Road , Tirupur – 641 601`,
          `SF No. 34 , Sri Venkateswara Nagar , 1st Street , 
Near Velan Hotel ,\n Kangayem Main Road , Tirupur – 641 601`]
      }
    ]
  }
]


export const STATES = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Lakshadweep',
  'Puducherry'

]

export const DONATION_CREDENTIALS = [
  'Indian Trust Act – Registration Number:BNG(U)JNR 1287/2015-2016/BKIV',
  'u/s 80G Income Tax Act 1961',
  'NITI Aayog Registration Number: KA/2017/0180303',
  'GuideStar India Transparency Key GSN12510 (Valid till December 2022)'
]

export const PAYMENT_DETAILS = {
  'Account Name': 'WinVinaya Foundation',
  'Account Type': 'Current Account',
  'IFSC CODE': 'ICIC0001007',
  'Bank Name': 'ICICI Bank',
  'Account Number': '100705003279',
  'Branch Name': 'Bannerghatta Road'
}